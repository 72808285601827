/* src/css/CookieConsent.css */

.cookie-modal-overlay {
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
}

.cookie-modal-content {
  position: relative !important;
  inset: auto !important;
  max-width: 400px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 1.25rem !important;
  border-radius: 1rem !important;
  background-color: white !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  outline: none !important;
}

.cookie-modal-container {
  position: relative;
}

.cookie-content {
  text-align: center;
}

.cookie-icon {
  font-size: 2rem;
  margin-bottom: 0.75rem;
}

.cookie-description {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}

.cookie-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.cookie-link {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.cookie-link:hover {
  color: #333;
  text-decoration: underline;
}

.cookie-accept-button {
  padding: 0.5rem 1.5rem;
  color: white;
  background-color: #00C896;
  border: none;
  border-radius: 2rem;
  transition: transform 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
  font-size: 0.95rem;
}

.cookie-accept-button:hover {
  background-color: #00B486;
  transform: translateY(-1px);
}

.cookie-close-button {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.25rem;
  opacity: 0.6;
  transition: all 0.2s ease;
}

.cookie-close-button:hover {
  opacity: 1;
  transform: scale(1.1);
}