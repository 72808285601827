/* src/css/Pricing.css */

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem 1rem;
}

.pricing-header {
  text-align: center;
  margin-bottom: 3rem;
}

.pricing-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.pricing-header p {
  color: #666;
  font-size: 1.1rem;
}

.pricing-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.toggle-button {
  padding: 0.5rem 1.5rem;
  border: 1px solid #e2e8f0;
  background: #fff;
  border-radius: 2rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.toggle-button.active {
  background: #6366f1;
  color: white;
  border-color: #6366f1;
}

.pricing-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 0 auto;
  max-width: 1000px;
  margin-bottom: 3rem;
}

.pricing-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #e2e8f0;
}

.pricing-card.pro {
  border: 2px solid #6366f1;
}

.popular-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #6366f1;
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.card-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.price {
  font-size: 2.5rem;
  font-weight: 700;
}

.currency {
  font-size: 1.5rem;
  vertical-align: super;
}

.period {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
}

.card-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-features li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #4a5568;
}

.check-icon {
  color: #6366f1;
  margin-right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
}

.upgrade-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  margin-top: 2rem;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.upgrade-button.primary {
  background: #6366f1;
  color: white;
  border: none;
}

.upgrade-button.outline {
  background: transparent;
  border: 1px solid #6366f1;
  color: #6366f1;
}

.upgrade-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.pricing-faq {
  text-align: center;
  margin-top: 3rem;
}

.money-back {
  font-weight: 500;
  margin-bottom: 1rem;
}

.support-text {
  color: #666;
}

.support-text a {
  color: #6366f1;
  text-decoration: none;
}

@media (max-width: 768px) {
  .pricing-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 0 1rem;
  }
  
  .pricing-card {
    padding: 1.5rem;
  }
  
  .card-header h2 {
    font-size: 1.25rem;
  }
  
  .price {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .pricing-cards {
    grid-template-columns: 1fr;
  }
} 