/* src/css/RegisterForm.css */

.register-page {
  min-height: max(100vh - 2rem, 500px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding: 1rem;
  box-sizing: border-box;
}

.register-card {
  width: 100%;
  max-width: 420px;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.875rem;
  font-weight: 700;
  color: #1a1f36;
  margin-bottom: 0.75rem;
}

.subtitle {
  font-size: 0.875rem;
  color: #6b7280;
}

.link {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.link:hover {
  color: #2563eb;
}

.error-alert {
  display: flex;
  align-items: center;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #f87171;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.alert-message {
  color: #b91c1c;
  font-size: 0.875rem;
}

.register-form {
  margin-top: 2rem;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.form-input-container {
  width: 100%;
  margin-bottom: 1rem;
}

.input-label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #374151;
  margin-bottom: 0.5rem;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

/* 更新后的图标容器样式 */
.input-icon-container {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
}

.input-icon {
  width: 100%;
  height: 100%;
  color: #94a3b8;
}

.form-input {
  width: 100%;
  height: 3rem;
  padding: 0 3rem 0 3rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  font-size: 1rem;
  color: #1f2937;
  background-color: #f9fafb;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.form-input:hover {
  border-color: #3b82f6;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: white;
}

.form-input.input-error {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.form-input.with-toggle {
  padding-right: 3rem;
}

.password-toggle-button {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: all 0.2s ease;
}

.password-toggle-button:hover {
  background: #f3f4f6;
}

.error-message {
  margin-top: 0.375rem;
  font-size: 0.875rem;
  color: #ef4444;
}

.submit-button {
  width: 100%;
  height: 3rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
}

.submit-button:hover:not(:disabled) {
  background-color: #43a047;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.terms-text {
  text-align: center;
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 1rem;
}

.terms-text a {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
}

.terms-text a:hover {
  color: #2563eb;
}

@media (max-width: 640px) {
  .register-card {
    padding: 1.5rem;
  }
}