/* css/NewsList.css */

/* Container styles */
.news-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: #ffffff;
}

.news-title {
  margin-bottom: 24px !important;
  font-weight: 600 !important;
  color: #333333;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Loading, error and empty states */
.news-loading,
.news-error,
.news-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.news-loading .MuiCircularProgress-root {
  margin-bottom: 16px;
}

/* Grid and card layout */
.news-grid {
  margin-top: 8px !important;
}

.news-masonry-grid {
  display: flex;
  gap: 24px;
  width: 100%;
  margin-top: 16px;
}

.news-masonry-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.news-card {
  margin: 0 !important;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .news-masonry-grid {
    flex-direction: column;
    gap: 16px;
  }

  .news-masonry-column {
    width: 100%;
    gap: 16px;
  }
}

.news-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06) !important;
}

/* Card header styles */
.news-card-header {
  background-color: #ffffff;
  padding: 16px 20px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.news-card-header .MuiCardHeader-title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  color: #000000 !important;
  line-height: 1.4 !important;
}

/* Meta information */
.news-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
}

.news-source {
  font-size: 0.85rem;
  color: #555555;
  font-weight: 600;
  background-color: rgba(33, 150, 243, 0.08);
  padding: 4px 10px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
}

.news-date {
  font-size: 0.85rem;
  color: #666666;
  font-weight: 500;
}

.news-keyword-chip {
  height: 24px !important;
  font-size: 0.75rem !important;
  background-color: rgba(33, 150, 243, 0.06) !important;
  border: 1px solid rgba(33, 150, 243, 0.2) !important;
  color: #6366f1 !important;
}

/* Image styles */
.news-image-wrapper {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
  background-color: #f8f9fa;
}

.news-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.news-image:hover {
  transform: scale(1.05);
}

/* 替换原有的 news-image-wrapper 相关样式 */
.news-thumbnail-container {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
  background-color: #f5f5f5;
  cursor: pointer;
}

.thumbnail-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #f5f5f5 25%, #eeeeee 50%, #f5f5f5 75%);
  background-size: 200% 200%;
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.news-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: pixelated;
  transform: scale(1.02);
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(0.97);
  max-width: 800px;
  image-resolution: 96dpi;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.news-thumbnail-container:hover .thumbnail-overlay {
  opacity: 1;
}

.news-thumbnail-container:hover .news-thumbnail {
  transform: scale(1.15);
}

/* Content area */
.news-card-content {
  padding: 16px 20px !important; /* 减小上下内边距 */
  min-height: 100px; /* 减小最小高度 */
  background-color: #ffffff;
}

/* Card actions */
.news-card-actions {
  padding: 12px 20px !important; /* 减小上下内边距 */
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: flex-start; /* 让按钮靠左对齐 */
  background-color: #ffffff;
  margin-top: auto; /* 确保按钮紧贴内容 */
}

.news-view-button {
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 6px 16px !important;
  border-radius: 6px !important;
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  padding: 32px 0 16px;
  margin-top: 16px;
}

.news-pagination {
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.news-pagination .MuiPaginationItem-root {
  margin: 0 4px;
  min-width: 36px;
  height: 36px;
  font-size: 0.95rem;
  border-radius: 6px;
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar styling */
.news-container::-webkit-scrollbar {
  width: 8px;
}

.news-container::-webkit-scrollbar-track {
  background: #f8f9fa;
  border-radius: 4px;
}

.news-container::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 4px;
}

.news-container::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

/* Responsive design */
@media (max-width: 960px) {
  .news-container {
    padding: 16px;
  }
  
  .news-title {
    font-size: 1.25rem !important;
    margin-bottom: 16px !important;
  }
  
  .news-card-header .MuiCardHeader-title {
    font-size: 1rem !important;
  }
  
  .news-image-wrapper {
    position: relative;
    width: 100%;
    height: 200px; /* 稍微减小图片高度 */
    overflow: hidden;
    background-color: #f8f9fa;
  }
  
  .news-pagination .MuiPaginationItem-root {
    min-width: 32px;
    height: 32px;
    font-size: 0.875rem;
  }
}

/* Override dark mode to maintain white theme */
@media (prefers-color-scheme: dark) {
  .news-container,
  .news-card,
  .news-card-header,
  .news-card-content,
  .news-card-actions,
  .news-pagination {
    background-color: #ffffff !important;
  }
  
  .news-title {
    color: #333333;
  }
  
  .news-source {
    color: #555555;
    background-color: rgba(33, 150, 243, 0.08);
  }
  
  .news-date {
    color: #666666;
  }
  
  .news-keyword-chip {
    background-color: rgba(33, 150, 243, 0.06) !important;
    border-color: rgba(33, 150, 243, 0.2) !important;
    color: #6366f1 !important;
  }
}

/* Loading skeleton animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton-loading {
  background: linear-gradient(90deg, 
    rgba(0, 0, 0, 0.06) 25%, 
    rgba(0, 0, 0, 0.08) 37%, 
    rgba(0, 0, 0, 0.06) 63%
  );
  background-size: 400% 100%;
  animation: shimmer 1.4s ease infinite;
}

/* Error state enhancements */
.news-error .MuiAlert-root {
  width: 100%;
  max-width: 500px;
}