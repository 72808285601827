/* App.css */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f4f8; /* 更柔和的背景色 */
  min-height: 100vh;
  font-family: 'Roboto', Arial, sans-serif; /* 替换为更现代的字体 */
}

.url-form {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.url-input {
  padding: 12px;
  width: 400px;
  max-width: 80%;
  border: 2px solid #4a90e2;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease; /* 添加过渡效果 */
}

.url-input:focus {
  border-color: #357ABD; /* 输入框聚焦时的效果 */
}

.url-hint {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
  text-align: center;
}

.summarize-btn {
  padding: 12px 25px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

.summarize-btn:hover {
  background-color: #357ABD;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* 轻微的向上移动效果 */
}

.loading-spinner {
  margin-top: 20px;
  font-size: 16px;
  color: #007bff;
  font-weight: bold;
}

.summary-display {
  margin-top: 30px;
  padding: 20px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  max-width: 650px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.summary-display h2 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}

.summary-display p {
  line-height: 1.8; /* 增加行间距，提升可读性 */
  text-align: left;
  margin-bottom: 15px; /* 增加段落之间的间距 */
  font-size: 16px;
}

/* Container for URL input and summary */
.url-summary-section {
  width: 100%;
  margin-bottom: 20px; /* Add space below the URL and summary section */
}

/* New content section wrapping both favorites and categories */
.content-section {
  display: flex; /* Flexbox to place them side by side */
  justify-content: space-between; /* Spread them apart */
  width: 100%; /* Ensure it spans the full width */
}

/* Adjust sizes for favorites and categories */
.favorites-section {
  flex: 2 1; /* Make the favorites section take more space */
  padding-right: 30px; /* Increase padding to avoid overlap */
}

.category-section {
  flex: 1 1;
  padding-left: 20px; /* Keep the padding but remove the border */
  border-left: none; /* This removes the dividing line */
}

.category-section h2 {
  font-size: 1.25rem;
  color: #4a90e2;
  margin-bottom: 15px;
  text-align: left;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .content-section {
    flex-direction: column;
    margin-top: 20px; /* Add some margin for better separation */
  }

  .favorites-section, .category-section {
    width: 100%;
    padding: 10px 0;
  }

  .category-section {
    border-left: none; /* Remove the border on small screens */
    padding-top: 20px;
  }
}

.category-list {
  list-style-type: none;
  padding: 0;
}

.category-list li {
  padding: 12px 15px; /* Increase padding for better click area */
  margin-bottom: 10px; /* Increase margin for better spacing */
  background-color: #f7f7f7; /* Default background for categories */
  border-radius: 6px;
  transition: background-color 0.3s ease;
  position: relative; /* Ensure the cancel icon is positioned correctly */
  cursor: pointer; /* Indicate clickable */
}

.category-list li.active {
  background-color: attr(data-color); /* Use dynamic pastel color for active category */
  color: #000000; /* Keep text black */
}

/* Style for the cancel icon */
.category-list li .cancel-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #000000; /* Black color */
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.category-list li .cancel-icon:hover {
  color: #ff4d4f; /* Red when hovered */
}

.category-list li:hover {
  background-color: attr(data-color); /* Use the dynamically assigned color */
  color: #000000; /* Ensure text remains black */
}

/* 全局骨架屏样式 */
.loading-skeleton {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.skeleton-header {
    height: 60px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.skeleton-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.skeleton-line {
    height: 24px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
}

/* 导航按钮骨架屏样式 */
.nav-btn.skeleton-btn {
    border: none;
    color: transparent;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    pointer-events: none;
}

.nav-btn.skeleton-btn .btn-content {
    visibility: hidden;
}

.nav-btn.skeleton-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

/* 响应式调整 */
@media (max-width: 768px) {
    .loading-skeleton {
        padding: 0.5rem;
    }
    
    .skeleton-header {
        height: 50px;
    }
}

