/* css/LandingPage.css */

/* 基础样式 */
.landing-page {
  min-height: 100vh;
  width: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  background: linear-gradient(
    160deg,
    #f8fafc 0%,
    #eef2ff 35%,
    #ffffff 100%
  );
}

/* 导航栏 */
.nav-container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(231, 235, 240, 0.8);
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.logo-icon {
  width: 35px;
  height: 35px;
  margin-right: 0px;
  display: block; /* 添加这行 */
}

.logo-text {
  font-size: 1.25rem;
  font-weight: bold;
  color: #000000;
  line-height: 24px; /* 添加这行，与图标高度相同 */
  display: block; /* 添加这行 */
}

.nav-buttons {
  display: flex;
  gap: 1rem;
}

.nav-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  border: none;
  background: none;
  color: #4b5563;
  min-width: 100px; /* 确保按钮有最小宽度 */
}  

.nav-btn:hover {
  color: #1f2937;
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* 统一的图标和文字间距 */
}

.btn-icon {
  width: 20px;
  height: 20px;
  display: block;
  flex-shrink: 0;
}

.btn-text {
  display: block;
  line-height: 20px;
}

.nav-btn.outline {
  border: 1px solid #d1d5db;
}

.nav-btn.outline:hover {
  background-color: #f3f4f6;
}

.nav-btn.primary {
  background-color: #4f46e5;
  color: white;
}

.nav-btn.primary:hover {
  background-color: #4338ca;
}

/* 主要内容区域 */
.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Hero 部分 */
.hero-section {
  text-align: center;
  margin-bottom: 4rem;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero-description {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
}

.hero-description-line {
  font-size: 1.25rem;
  color: #3e4146;
  margin: 0;
  line-height: 1.6;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .hero-description-line {
    font-size: 1.125rem;
    padding: 0 1rem;
  }
}

/* Demo 部分 */
.demo-section {
  max-width: 32rem;
  margin: 0 auto 4rem;
}

.demo-form {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.url-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(231, 235, 240, 0.8);
  border-radius: 0.375rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
}

.url-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 4px rgba(79, 70, 229, 0.1);
}

.url-input:disabled {
  background-color: #f9fafb;
  cursor: not-allowed;
}

.submit-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #4f46e5 0%, #4338ca 100%);
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
  justify-content: center;
}

.submit-btn:hover:not(:disabled) {
  background: linear-gradient(135deg, #4338ca 0%, #3730a3 100%);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.submit-btn:active:not(:disabled) {
  transform: translateY(0);
}

.submit-btn:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-btn:hover:disabled {
  background-color: #93c5fd; /* 确保禁用状态下悬停时不改变颜色 */
  transform: none; /* 禁用位移效果 */
}

.submit-btn.loading {
  background-color: #4a90e2;
  cursor: wait;
}

.submit-btn.loading:hover {
  background-color: #4a90e2; /* 防止悬停时背景色变化 */
  transform: none; /* 禁用悬停时的位移效果 */
}

.submit-btn.loading:active {
  transform: none; /* 禁用点击时的位移效果 */
}

.submit-btn.disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
  opacity: 0.7;
}

.submit-btn:disabled:not(.loading) {
  background-color: #4a90e2;
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-btn.loading::after {
  content: "";
  border: 2px solid #fff;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-left: 10px;
}

/* 禁用状态但不是加载中时不显示旋转动画 */
.submit-btn:disabled:not(.loading)::after {
    display: none;
}

.submit-btn:not(.empty-url)[title]:hover::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
  margin-bottom: 0.5rem;
}

/* 空 URL 时的样式 */
.submit-btn.empty-url {
  background-color: #4a90e2;
  opacity: 1;
}

/* 空 URL 时的悬停效果 */
.submit-btn.empty-url:hover {
  background-color: #357abd;
  cursor: pointer;
}
  
.loading-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.loading-spinner {
  width: 1.25rem;
  height: 1.25rem;
  animation: spin 1s linear infinite;
}

.spinner-circle {
  opacity: 0.25;
}

.spinner-path {
  opacity: 0.75;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.loading-dots::after {
  content: '...';
  animation: dots 1.5s steps(4, end) infinite;
  display: inline-block;
  vertical-align: bottom;
  min-width: 24px;
  text-align: left;
}

@keyframes dots {
  0%, 20% { content: ''; }
  40% { content: '.'; }
  60% { content: '..'; }
  80%, 100% { content: '...'; }
}

.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #fef2f2;
  border-radius: 0.375rem;
  border: 1px solid #fee2e2;
}

.demo-hint {
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.signup-hint {
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
}

.example-urls-section {
  margin-top: 1.5rem;
  text-align: center;
}

.example-urls-title {
  font-size: 0.875rem;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 500;
}

.example-urls-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 1.5rem; /* 调整间距 */
}

.example-url-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(231, 235, 240, 0.8);
  border-radius: 9999px;
  color: #374151;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
}

.example-url-button:hover {
  background: white;
  border-color: #4f46e5;
  color: #4f46e5;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.1);
}

.example-url-button:active {
  transform: translateY(0);
}

.example-url-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.example-url-icon {
  font-size: 1.125rem;
}

.example-url-label {
  white-space: nowrap;
}

/* 响应式调整 */
@media (max-width: 640px) {
  .example-urls-list {
    flex-direction: column;
    align-items: stretch;
    padding: 0 1rem;
  }

  .example-url-button {
    justify-content: center;
  }
}

/* Features 部分 */
.features-section {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 2rem 1rem;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 1000px;
  margin: 2rem auto 0;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #1f2937;
}

.example-tabs {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabs {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tabs-header {
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  padding: 0.5rem;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  font-size: 1rem;
  color: #4b5563;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s;
}

.tab-button:hover {
  background: #f3f4f6;
}

.tab-button.active {
  background: #e5e7eb;
  color: #1f2937;
}

.tab-icon {
  font-size: 1.25rem;
}

.tab-content {
  padding: 1.5rem;
}

/* Saved Articles 样式 */
.article-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.article-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.article-url {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.article-summary {
  margin-bottom: 1rem;
}

.summary-header {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.summary-points {
  list-style-type: none;
  padding-left: 0;
}

.summary-points li {
  margin-bottom: 0.25rem;
  padding-left: 1.5rem;
  position: relative;
}

.summary-points li::before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: #6b7280;
}

.article-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.article-category {
  color: #6b7280;
  font-size: 0.875rem;
}

.article-actions {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #e5e7eb;
  background: white;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.2s;
}

.action-btn:hover {
  background: #f3f4f6;
}

.action-btn.delete {
  color: #ef4444;
  border-color: #fecaca;
}

.action-btn.delete:hover {
  background: #fef2f2;
}

/* Related News 样式 */
.news-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.news-source {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.news-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.news-date {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.news-footer {
  display: flex;
  justify-content: flex-end;
}

.read-more-btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: none;
  background: #4a90e2;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.read-more-btn:hover {
  background: #357abd;
}

/* Email Signup 部分 */
.signup-section {
  padding: 4rem 1rem;
}

.signup-content {
  max-width: 1200px;
  margin: 0 auto;
}

.section-description {
  font-size: 1.125rem;
  color: #4b5563;
  margin-bottom: 2rem;
}

.signup-container {
  max-width: 32rem;
  margin: 0 auto;
}

.signup-form {
  max-width: 28rem;
  margin: 2rem auto;
  display: flex;
  gap: 0.5rem;
}

.email-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 1rem;
}

.buttons-wrapper {
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}  

.signup-btn {
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}  

.signup-btn:hover {
  background-color: #357abd;
}

.signup-btn:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.signup-btn.primary {
  background: linear-gradient(135deg, #4f46e5 0%, #4338ca 100%);
  border: none;
  color: white;
  transition: all 0.3s ease;
}

.signup-btn.primary:hover {
  background: linear-gradient(135deg, #4338ca 0%, #3730a3 100%);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.signup-btn.secondary {
  background: white;
  border: 1px solid rgba(231, 235, 240, 0.8);
  color: #4f46e5;
}

.signup-btn.secondary:hover {
  background: rgba(79, 70, 229, 0.05);
  border-color: #4f46e5;
}

.benefits-grid {
  margin-top: 2rem;
  margin-bottom: 2rem; /* 添加底部间距 */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.benefit-item {
  display: flex;
  justify-content: center;
}

.benefit-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.check-icon {
  width: 16px;
  height: 16px;
  color: #4a90e2;
  flex-shrink: 0;
}

.benefit-text {
  display: block;
  line-height: 16px;
  color: #4b5563;
}

/* 响应式调整 */
@media (max-width: 640px) {
  .signup-section {
    padding: 2rem 1rem;
  }
  
  .buttons-wrapper {
    max-width: 100%;
  }
}

  /* 添加新的样式类来处理Pro plans链接部分 */
.pro-plans-link {
  margin-top: 2rem; /* 增加顶部间距 */
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.pro-plans-link a {
  color: #4f46e5;
  text-decoration: none;
  font-weight: 500;
}

.pro-plans-link a:hover {
  text-decoration: underline;
}

/* Footer 样式优化 */
.footer {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(231, 235, 240, 0.8);
}

.footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1.5rem 1rem;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.footer-link {
  color: #4f46e5;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-link:hover {
  color: #3730a3;
}

.footer-separator {
  color: #d1d5db;
  margin: 0 0.5rem;
}

.footer-copyright {
  margin-top: 1rem;
  text-align: center;
  color: #111827;
  font-size: 0.875rem;
}

/* 响应式布局 */
@media (max-width: 640px) {
  .footer-links {
    gap: 1rem;
  }
  
  .footer-separator {
    display: none;  /* 在移动端隐藏分隔符 */
  }
  
  .footer-link {
    padding: 0.5rem 0;  /* 增加移动端点击区域 */
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .demo-form {
    flex-direction: column;
  }

  .submit-btn {
    width: 100%;
    min-width: auto;
  }

  .url-input {
    width: 100%;
  }

  .loading-text {
    justify-content: center;
  }

  .signup-form {
    flex-direction: column;
  }

  .signup-btn {
    width: 100%;
  }

  .features-section {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 640px) {
  .nav-container {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-buttons {
    width: 100%;
    justify-content: center;
  }
}

/* FAQ Section Styles */
.faq-section {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 2rem 1rem;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.faq-item {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(10px);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
}

.faq-question {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.faq-answer {
  color: #6b7280;
  line-height: 1.6;
  font-size: 1rem;
}

/* Responsive adjustments for FAQ section */
@media (max-width: 768px) {
  .faq-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .faq-item {
    padding: 1.25rem;
  }

  .faq-question {
    font-size: 1.125rem;
  }
}

/* Enhanced Signup Section Styles */
.value-propositions {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
}

.value-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.value-description {
  font-size: 1.125rem;
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.testimonial-section {
  margin: 3rem auto;
  max-width: 600px;
  text-align: center;
}

.testimonial {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.testimonial-text {
  font-size: 1.125rem;
  color: #1f2937;
  line-height: 1.6;
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonial-author {
  color: #4b5563;
  font-size: 0.875rem;
}

.testimonial-author cite {
  font-style: normal;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .highlights-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .value-title {
    font-size: 1.25rem;
  }

  .value-description {
    font-size: 1rem;
  }

  .testimonial {
    padding: 1.5rem;
  }

  .testimonial-text {
    font-size: 1rem;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .features-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1024px) {
  .features-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .features-section {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
}

.highlight-item {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 0.75rem;
  text-align: center;
  border: 1px solid rgba(231, 235, 240, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.highlight-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);
}

.highlight-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.highlight-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.highlight-text {
  color: #6b7280;
  line-height: 1.5;
  font-size: 0.95rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .highlights-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}