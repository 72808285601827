.account-settings-container {
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 2rem 1rem;
}

.account-settings-wrapper {
  max-width: 32rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.account-settings-header {
  text-align: center;
  margin-bottom: 1rem;
}

.main-title {
  font-size: 2.25rem;
  font-weight: 800;
  color: #111827;
  margin-bottom: 0.75rem;
  letter-spacing: -0.025em;
}

.main-description {
  font-size: 1rem;
  color: #6b7280;
  line-height: 1.5;
}

.settings-container {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1),
              0 10px 15px -3px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  overflow: hidden;
}

.settings-section {
  padding: 1.75rem;
  border-bottom: 1px solid #e5e7eb;
  transition: background-color 0.2s ease;
}

.settings-section:hover {
  background-color: #f8fafc;
}

.settings-section:last-child {
  border-bottom: none;
}

.settings-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.settings-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.375rem;
}

.settings-description {
  font-size: 0.925rem;
  color: #6b7280;
  line-height: 1.5;
}

.settings-icon {
  height: 1.5rem;
  width: 1.5rem;
  color: #9ca3af;
  margin-top: 0.25rem;
}

.settings-content {
  margin-top: 1.25rem;
}

.action-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.75rem;
  font-size: 0.925rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.action-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
}

.action-button.default {
  background-color: #2563eb;
  color: white;
}

.action-button.default:hover:not(:disabled) {
  background-color: #1d4ed8;
  transform: translateY(-1px);
}

.action-button.danger {
  background-color: #dc2626;
  color: white;
}

.action-button.danger:hover:not(:disabled) {
  background-color: #b91c1c;
  transform: translateY(-1px);
}

.button-icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.75rem;
}

.button-icon.spinning {
  animation: spin 1s linear infinite;
}

.manage-emails-link {
  margin-top: 0.75rem;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.75rem;
  font-size: 0.925rem;
  font-weight: 600;
  color: #374151;
  background-color: white;
  transition: all 0.2s ease;
  text-decoration: none;
}

.manage-emails-link:hover {
  background-color: #f8fafc;
  border-color: #d1d5db;
  transform: translateY(-1px);
}

.manage-emails-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
}

.error-message,
.success-message {
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 0.925rem;
  font-weight: 500;
}

.error-message {
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
}

.success-message {
  background-color: #f0fdf4;
  border: 1px solid #dcfce7;
}

.alert-icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.error-message .alert-icon {
  color: #dc2626;
}

.success-message .alert-icon {
  color: #22c55e;
}

.error-message span {
  color: #991b1b;
}

.success-message span {
  color: #166534;
}

.verification-status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
}

.verification-status.verified {
  background-color: #ecfdf5;
  color: #059669;
}

.verification-status.not-verified {
  background-color: #fef3c7;
  color: #d97706;
}

@media (max-width: 640px) {
  .account-settings-wrapper {
    max-width: 100%;
  }

  .main-title {
    font-size: 1.875rem;
  }

  .settings-section {
    padding: 1.25rem;
  }

  .action-button,
  .manage-emails-link {
    padding: 0.625rem 1.25rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 