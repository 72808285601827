/* css/Footer.css */
.App-footer {
  padding: 40px 20px;
  margin-top: 60px;
  text-align: center;
  background-color: #f8fafc;
  color: #64748b;
  border-top: 1px solid #e2e8f0;
  width: 100%;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.05);
}

.App-footer p {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.App-footer a {
  color: #475569;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 8px 0;
  position: relative;
  transition: color 0.2s ease;
}

.App-footer a:hover {
  color: #3b82f6;
  text-decoration: none;
}

/* 添加悬停时的下划线动画效果 */
.App-footer a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3b82f6;
  transition: width 0.3s ease;
}

.App-footer a:hover::after {
  width: 100%;
}

/* 移除链接之间的分隔符 */
.App-footer p a + a {
  margin-left: 0;
}

@media (max-width: 768px) {
  .App-footer {
    padding: 30px 15px;
  }
  
  .App-footer p {
    flex-direction: column;
    gap: 16px;
  }
}
