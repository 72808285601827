/* css/UrlForm.css */
:root {
    --primary: #4666F6;
    --text-primary: #1A1D1F;
    --text-secondary: #6F767E;
    --background: #ffffff;
    --border: #E6E8EC;
    --button-bg: #1A1D1F;
    --button-hover: #333333;
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.url-section {
    background-color: var(--background);
    padding: 0.75rem 1rem;
    min-height: 80px;
    display: flex;
    flex-direction: column;
}

.url-form-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;  /* 从 0.75rem 增加到 1rem */
}

.brand-section {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.5rem;  /* 从 0.375rem 增加到 0.5rem，增加图标和文字间距 */
}

.brand-logo {
    width: 38px;  /* 从 18px 增加到 24px */
    height: 38px;
    color: var(--primary);
}

.brand-name {
    font-size: 1.5rem;  /* 从 1.125rem 增加到 1.5rem */
    font-weight: 600;
    color: var(--text-primary);
}

.form-section {
    width: 100%;
}

.input-wrapper {
    display: flex;
    gap: 0.375rem;
    width: 100%;
    background: var(--background);
}

.url-input {
    flex: 1;
    padding: 0.75rem 1rem;  /* 上下内边距从 0.5rem 增加到 0.75rem */
    font-size: 1rem;  /* 字体从 0.875rem 增加到 1rem */
    color: var(--text-primary);
    background-color: var(--background);
    border: 2px solid var(--text-primary);
    border-radius: 6px;
    transition: all 0.2s ease;
}

.url-input:hover {
    border-color: #000000;
}

.url-input:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(70, 102, 246, 0.15);
}

.url-input::placeholder {
    color: var(--text-secondary);
}

.summarize-btn {
    padding: 0.75rem 1.25rem; /* 调整按钮内边距以匹配输入框高度 */
    min-width: 100px;  /* 稍微增加最小宽度 */
    font-size: 1rem;  /* 匹配输入框字体大小 */
    font-weight: 500;
    color: var(--background);
    background-color: var(--button-bg);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
    white-space: nowrap;
}

.summarize-btn:hover:not(:disabled) {
    background-color: var(--button-hover);
}

.summarize-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.loading-text {
    display: flex;
    align-items: center;
}

.loading-content {
    display: flex;
    align-items: center;
    gap: 0.375rem;
}

.loading-spinner {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid var(--background);
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
    .url-section {
        padding: 0.75rem;
    }

    .header-section {
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 480px) {
    .url-section {
        padding: 0.5rem;
    }

    .input-wrapper {
        flex-direction: column;
    }

    .summarize-btn {
        width: 100%;
    }
}