/* css/UserMenu.css */
:root {
  --menu-bg: #ffffff;
  --menu-text: #1A1D1F;
  --menu-text-secondary: #6F767E;
  --menu-border: #E6E8EC;
  --menu-hover: #F4F5F6;
  --menu-danger: #dc2626;
  --menu-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --menu-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.user-menu-container {
position: relative;
z-index: 50;
}

.user-menu-trigger {
display: flex;
align-items: center;
gap: 0.75rem;
background-color: transparent;
border: 1px solid var(--menu-border);
padding: 0.5rem 1rem;
border-radius: 8px;
color: var(--menu-text);
transition: all var(--menu-transition);
cursor: pointer;
}

.user-menu-trigger:hover,
.user-menu-trigger:focus-visible {
background-color: var(--menu-hover);
border-color: var(--menu-border);
}

.user-avatar {
width: 2rem;
height: 2rem;
background-color: var(--menu-hover);
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-weight: 500;
font-size: 0.875rem;
color: var(--menu-text);
flex-shrink: 0;
}

.user-email {
font-size: 0.875rem;
color: var(--menu-text);
max-width: 200px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.user-menu-dropdown {
position: absolute;
right: 0;
top: calc(100% + 0.5rem);
width: 16rem;
background-color: var(--menu-bg);
border-radius: 12px;
box-shadow: var(--menu-shadow);
border: 1px solid var(--menu-border);
opacity: 0;
visibility: hidden;
transform: translateY(-8px) scale(0.98);
transition: all var(--menu-transition);
transform-origin: top right;
}

.user-menu-dropdown.active {
opacity: 1;
visibility: visible;
transform: translateY(0) scale(1);
}

.user-menu-content {
overflow: hidden;
border-radius: 12px;
}

.user-menu-header {
padding: 1rem;
border-bottom: 1px solid var(--menu-border);
background: linear-gradient(to bottom, white, rgba(79, 70, 229, 0.03));
}

.user-menu-email {
font-size: 0.875rem;
color: var(--menu-text-secondary);
line-height: 1.25;
}

.menu-group {
padding: 0.25rem 0;
}

.menu-group + .menu-group {
border-top: 1px solid var(--menu-border);
}

.menu-item {
display: flex;
align-items: center;
width: 100%;
padding: 0.75rem 1rem;
font-size: 0.875rem;
color: var(--menu-text);
background: none;
border: none;
cursor: pointer;
transition: all var(--menu-transition);
}

.menu-item:hover,
.menu-item:focus-visible {
background-color: var(--menu-hover);
}

.menu-icon {
width: 1rem;
height: 1rem;
margin-right: 0.75rem;
color: var(--menu-text-secondary);
}

.menu-item-danger {
color: var(--menu-danger);
}

.menu-item-danger .menu-icon {
color: var(--menu-danger);
}

/* Responsive Design */
@media (max-width: 768px) {
.user-email {
  max-width: 150px;
}

.user-menu-trigger {
  padding: 0.5rem;
}
}

@media (max-width: 480px) {
.user-email {
  display: none;
}

.user-menu-trigger {
  padding: 0.375rem;
}

.user-menu-dropdown {
  right: -0.5rem;
}

.user-avatar {
  width: 1.75rem;
  height: 1.75rem;
}
}

.user-avatar.premium {
background: linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%);
color: white;
position: relative;
}

.premium-badge {
position: absolute;
bottom: -2px;
right: -2px;
background: #fbbf24;
border-radius: 50%;
padding: 2px;
color: white;
border: 2px solid white;
}

.premium-status {
display: flex;
align-items: center;
gap: 4px;
padding: 4px 8px;
background: linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%);
color: white;
border-radius: 12px;
font-size: 12px;
margin-top: 8px;
margin-bottom: 4px;
}

.premium-icon {
color: #fbbf24;
}

.subscription-info {
margin-top: 8px;
padding: 8px;
background: rgba(79, 70, 229, 0.1);
border-radius: 8px;
}

.subscription-date {
display: flex;
align-items: center;
gap: 6px;
color: var(--menu-text);
font-size: 12px;
}

.subscription-icon {
color: #4f46e5;
}

.subscription-remaining {
margin-top: 4px;
font-size: 11px;
color: var(--menu-text-secondary);
padding-left: 20px;
}

.upgrade-prompt {
  padding: 8px 16px;
  border-top: 1px solid #eee;
}

.upgrade-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background-color: #3b82f6;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upgrade-button:hover {
  background-color: #2563eb;
}

.upgrade-button .menu-icon {
  color: white;
}