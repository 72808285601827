/* src/css/favorites/layout-and-utils.css */

/* ========== 基础布局样式 ========== */
.favorites-header {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.header-content {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  box-sizing: border-box;
}

.header-content h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  text-align: left;
}

.favorites-content {
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  box-sizing: border-box;
}

.favorites-list {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.favorites-list-column {
  padding: 0;
  width: 50% !important;
  box-sizing: border-box;
}

.favorites-list-column:first-child {
  padding-right: 10px;
}

.favorites-list-column:last-child {
  padding-left: 10px;
}

.favorite-footer p span:last-child {
  color: #666; /* 灰色 */
  font-size: 0.875rem; /* 14px */
}

/* Search Input */
.search-input {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #4a90e2;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* ========== 操作按钮样式 ========== */
.article-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 0 10px;
}

.left-actions {
  display: flex;
  gap: 15px;
}

.right-actions {
  display: flex;
  gap: 15px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
}

.icon-button:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

/* 添加工具提示 */
.icon-button::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.icon-button:hover::after {
  opacity: 1;
  visibility: visible;
  bottom: calc(100% + 5px);
}

/* 图标颜色 */
.chat-icon {
  color: #4caf50;
}

.delete-icon {
  color: #ff4d4f;
}

.expand-icon {
  color: #1890ff;
}

/* 删除按钮的特殊悬停效果 */
.icon-button .delete-icon {
  transition: all 0.2s ease;
}

.icon-button:hover .delete-icon {
  color: #ff1f1f;
  transform: scale(1.1);
}

.expand-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.expand-button:hover {
  color: #333;
  background-color: #f5f5f5;
  border-radius: 4px;
}

/* ========== 图片相关样式 ========== */
.article-main-image {
  position: relative !important;
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background-color: #f5f5f5;
  content-visibility: auto;
  contain: content;
}

.article-main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.02);
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(0.97);
}

.article-main-image::after {
  content: attr(data-tooltip);
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
}

.article-main-image:hover::after {
  opacity: 1;
}

.article-main-image:hover img {
  transform: scale(1.15);
}

.category-icon {
  width: 72px;
  height: 40.5px;
  margin-right: 16px;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
}

.category-icon.loading {
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.category-icon.loading::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border: 3px solid #e5e7eb;
  border-top-color: #3b82f6;
  border-radius: 50%;
  animation: icon-spin 1s linear infinite;
}

/* ========== 响应式设计样式 ========== */
@media (max-width: 768px) {
  .favorites-list {
    columns: 1;
  }

  .categories-dropdown-content {
    min-width: 300px;
    right: -100px;
  }

  .category-list {
    grid-template-columns: 1fr;
  }

  .header-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .categories-dropdown {
    width: 100%;
  }

  .all-categories-btn {
    width: 100%;
    text-align: left;
  }

  .categories-dropdown-content {
    width: 100%;
    left: 0;
    right: auto;
  }

  .article-actions {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .favorites-header {
    padding: 8px 4px;
  }

  .header-content {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  /* 调整搜索框样式 */
  .search-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.875rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    margin-bottom: 0.75rem;
  }

  /* 调整分类下拉菜单 */
  .categories-dropdown {
    width: 100%;
  }

  .categories-dropdown-content {
    width: 100vw;
    min-width: auto;
    left: -0.5rem;
    right: -0.5rem;
    position: fixed;
    margin: 0;
    border-radius: 0;
  }

  /* 调整分类列表布局 */
  .category-list {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .article-actions {
    flex-direction: column;
  }

  /* 分类选择器样式 */
  .all-categories-btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 0.875rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* 标题样式 */
  .favorites-header h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  /* 内容区域样式 */
  .favorites-content {
    padding: 0 4px;
    width: 100%;
    max-width: 100%;
  }
}

/* ========== 动画 ========== */
@keyframes icon-spin {
  to {
    transform: rotate(360deg);
  }
}

