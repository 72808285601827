/* src/css/favorites/cards.css */

/* 卡片样式相关 */
.favorites-list li,
.expanded-item-container li {
  list-style: none !important;
  position: relative;
  /* z-index: 2; */
  overflow: visible !important;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 12px;
  border-color: rgb(229, 231, 235);
  border-width: 1px;
  border-style: solid;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  background: white;
  transition: transform 0.2s ease;
}

.favorites-list li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.favorites-list li.expanded,
.favorites-list li.isNew {
  width: 100%;
}

.favorite-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.expanded-item-container {
  list-style: none !important;
  display: grid;
  grid-column: 1 / -1;
  width: 100%;
  margin: 20px 0;
  transition: all 0.3s ease-in-out;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.expanded-item {
  grid-column: 1 / -1;
  width: 100% !important;
  max-width: none !important;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
}

.expanded-item::marker {
  display: none;
}

.expanded-item-container li::marker {
  display: none !important;
  content: none !important;
}

.favorites-list li::marker {
  display: none !important;
  content: none !important;
}

/* 链接样式 */
.favorites-list li a {
  position: relative;
  color: #666;
  font-weight: normal;
  text-decoration: none;
  font-size: 0.875rem;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px 12px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
}

.favorites-list li a:hover {
  background: #e5e5e5;
  color: #333;
  text-decoration: none;
}

.favorites-list li a:hover::after {
  content: attr(href);
  position: absolute;
  bottom: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  margin-bottom: 5px;
}

.favorites-list p {
  line-height: 1.8;
  margin-bottom: 15px;
}

/* 标题提示词样式 */
.favorites-list li h2[data-tooltip] {
  position: relative;
}

.favorites-list li h2[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1000;
  margin-top: 5px;
  font-weight: normal;
}

/* Summary Section Styles */
.summary-section {
    margin: 20px 0;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
}

.summary-content {
    display: flex;
    flex-direction: column;
}

/* 移除单独的 summary-section-item 背景和边框 */
.summary-section-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 8px 0;
}

.summary-section-item:hover {
    transform: none;
    background-color: transparent;
    box-shadow: none;
}

.summary-icon {
    min-width: 24px;
    height: 24px;
    margin-right: 12px;
    color: #4a90e2;
}

.summary-content-wrapper {
    flex: 1;
}

.summary-label {
    font-weight: 600;
    color: #2c3e50;
    font-size: 1.05em;
    margin-bottom: 8px;
}

.summary-text {
    color: #4a5568;
    line-height: 1.6;
    font-size: 0.95em;
}

/* Key Points List Styles */
.key-points-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* 提高选择器优先级 */
.favorites-list .key-points-list .key-point-item,
.expanded-item-container .key-points-list .key-point-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 4px 0;
    border-style: none !important; /* 使用 !important 确保覆盖 */
    margin: 0;
    background: transparent;
    box-shadow: none;
    width: auto;
}

.key-point-icon {
    color: #4a90e2;
    min-width: 24px;
    height: 24px;
    margin-right: 12px;
}

.key-point-content {
    flex: 1;
}

.key-point-title {
    font-weight: 600;
    color: #2c3e50;
    font-size: 1.05em;
    margin-bottom: 4px;
}

.key-point-detail {
    color: #4a5568;
    line-height: 1.6;
    font-size: 0.95em;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .summary-section {
        padding: 12px;
        margin: 15px 0;
    }

    .summary-label,
    .key-point-title {
        font-size: 0.95em;
    }

    .summary-text,
    .key-point-detail {
        font-size: 1.0em;
    }
}

/* Empty State */
.no-favorites {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

.no-favorites i {
  font-size: 40px;
  color: #4a90e2;
  margin-bottom: 10px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes borderBlink {
  0% { border-width: 4px; }
  50% { border-width: 8px; }
  100% { border-width: 4px; }
}

.blink-border {
  animation: borderBlink 0.5s ease-in-out 1;
}

/* 修改卡片列表的基础 z-index */
.favorites-list {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 640px) {
    /* 容器样式 */
    .favorites-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 两列布局 */
        gap: 6px; /* 减小卡片间距 */
        padding: 4px; /* 减小内边距 */
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
    }

    /* 卡片样式 */
    .favorites-list li,
    .expanded-item-container li {
        margin: 0;
        padding: 0;
        border-radius: 8px;
        background: white;
        border: none;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    /* 图片容器样式 */
    .article-main-image {
        width: 100%;
        padding-bottom: 100%; /* 1:1 宽高比 */
        height: 0;
        position: relative;
        margin: 0;
        border-radius: 0;
    }

    .article-main-image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* 内容样式 */
    .favorite-preview {
        padding: 6px;
    }

    /* 标题样式 */
    .favorites-list li h2 {
        font-size: 14px;
        line-height: 1.4;
        margin: 8px 0;
        font-weight: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* 分类标签样式 */
    .category-tag {
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 4px;
        background: #f5f5f5;
        margin-bottom: 4px;
        display: inline-block;
    }

    /* 底部信息样式 */
    .favorite-footer {
        font-size: 12px;
        color: #999;
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    /* 操作按钮样式 */
    .article-actions {
        margin-top: 8px;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    .icon-button {
        padding: 4px;
    }

    /* 展开状态样式 */
    .expanded-item-container {
        grid-column: 1 / -1; /* 跨越所有列 */
        margin: 0;
        padding: 0;
    }

    .expanded-item {
        width: 100% !important;
    }
}

/* 添加图标悬停效果 */
.icon-button:hover .delete-icon {
    color: #ff4444 !important; /* 更鲜艳的红色 */
}

.icon-button:hover .chat-icon {
    color: #00C851 !important; /* 更鲜艳的绿色 */
}

.icon-button:hover .expand-icon {
    color: #33b5e5 !important; /* 更鲜艳的蓝色 */
}

/* 可以继续添加其他图标的悬停效果 */ 