/* css/ChatPage.css */

/* General chat-page container */
.chat-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full screen height */
    justify-content: space-between; /* Align elements */
}

/* Link section */
.article-link-container {
    margin-bottom: 20px;
    font-size: 16px; /* Increased font size */
    font-weight: bold; /* Bold text for emphasis */
    color: #4a90e2;
    text-align: center;
    text-decoration: underline; /* Clear clickable indication */
}

.article-link-container a:hover {
    color: #3a7ec1; /* Hover effect for link */
}

/* Chat history */
.conversation-section {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Conversation group, mimicking a full-width style */
.conversation-group {
    display: flex;
    flex-direction: column;
    width: 100%; /* Fill entire width of the container */
    margin-bottom: 20px;
}

/* User's question */
.conversation-card.user {
    background-color: #f1f1f1;
    color: black;
    text-align: left;
    margin-bottom: 5px;
    padding: 12px;
    border-radius: 10px;
    width: 100%; /* Full width */
    word-wrap: break-word;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* System's answer */
.conversation-card.system {
    background-color: #e0f7fa;
    color: #333;
    text-align: left;
    margin-bottom: 5px;
    padding: 12px;
    border-radius: 10px;
    width: 100%; /* Full width */
    word-wrap: break-word;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

/* Custom scroll bar */
.conversation-section::-webkit-scrollbar {
    width: 8px;
}

.conversation-section::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
}

.conversation-section::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Conversation message text */
.conversation-card p {
    margin: 0;
    font-size: 16px;
    line-height: 1.7;
}

/* Time or status info below messages */
.conversation-card::after {
    content: '• Just now'; /* Placeholder for time info */
    font-size: 12px;
    color: #777;
    display: block;
    text-align: right;
    margin-top: 5px;
}

.conversation-card p strong {
    display: block;
    margin-bottom: 5px;
}

/* Input section at the bottom */
.input-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    z-index: 10;
}

.question-input {
    padding: 12px;
    flex-grow: 1;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px; /* Increased input font size */
    transition: border-color 0.3s ease;
}

.question-input:focus {
    border-color: #4a90e2;
}

button {
    padding: 12px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for the button */
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
    background-color: #388e3c;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Hover effect shadow */
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Fade-in animation for conversation bubbles */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
