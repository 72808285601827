/* src/css/LoginForm.css */
.login-page {
  min-height: max(100vh - 2rem, 500px);  /* 设置最小高度为视口高度减去padding,但不小于500px */
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
  padding: 1rem;
  box-sizing: border-box;  /* 确保padding计入总高度 */
}

@media (max-height: 600px) {
  .login-page {
    min-height: auto;  /* 在小屏幕上让高度自适应内容 */
    padding: 2rem 1rem;  /* 增加上下padding来保持适当的间距 */
  }
}

.login-card {
  width: 100%;
  max-width: 420px;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  margin: 1rem 0;
}

.card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.875rem;
  font-weight: 700;
  color: #1a1f36;
  margin-bottom: 0.75rem;
}

.subtitle {
  font-size: 0.875rem;
  color: #6b7280;
}

.link {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.link:hover {
  color: #2563eb;
}

.error-alert {
  display: flex;
  align-items: center;
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.alert-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #f87171;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.alert-message {
  color: #b91c1c;
  font-size: 0.875rem;
}

.login-form {
  margin-top: 2rem;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* 增加间距以容纳错误信息 */
  margin-bottom: 1.5rem;
}

.form-input-container {
  position: relative;
  width: 100%; /* 确保容器占满父元素宽度 */
}

.input-icon-container {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1; /* 确保图标在输入框上层 */
}

.input-icon {
  width: 1.5rem;  /* 增加图标大小 */
  height: 1.5rem;
  color: #9ca3af;
  transition: color 0.2s ease;
}

.form-input {
  width: 100%;
  height: 3rem;  /* 增加输入框高度 */
  padding: 0.75rem 1rem 0.75rem 2.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;  /* 增加圆角以匹配更大的高度 */
  font-size: 1rem;  /* 增加字体大小 */
  color: #1f2937;
  transition: all 0.2s ease;
  background-color: #f9fafb;
  box-sizing: border-box;
}

.form-input:hover {
  border-color: #3b82f6;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: white;
}

.with-toggle {
  padding-right: 3rem; /* 增加右侧padding以适应眼睛图标 */
}

.input-error {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.password-toggle-button {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: #9ca3af;
  transition: color 0.2s ease;
  z-index: 1; /* 确保按钮在输入框上层 */
}

.password-toggle-button:hover {
  color: #3b82f6;
}

.toggle-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.error-message {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #ef4444;
  position: absolute;
  width: 100%;
}

.form-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.remember-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remember-checkbox {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  transition: all 0.2s ease;
}

.remember-checkbox:checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.remember-text {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #4b5563;
}

.forgot-link {
  font-size: 0.875rem;
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.forgot-link:hover {
  color: #2563eb;
}

.submit-button {
  width: 100%;
  height: 3rem;  /* 增加按钮高度 */
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.75rem;  /* 增加圆角以匹配输入框 */
  font-size: 1rem;  /* 增加字体大小 */
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: #2563eb;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  .login-card {
    padding: 1.5rem;
  }
  
  .form-options {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-weight: 500;
  color: #1a202c;
  transition: all 0.2s;
}

.google-login-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e0;
}

.google-login-button .google-icon {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #718096;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e2e8f0;
}

.separator span {
  padding: 0 0.75rem;
  font-size: 0.875rem;
}