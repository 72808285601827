/* src/css/favorites/categories.css */

/* 分类相关样式 */
.active-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #f8fafc;
  border-radius: 6px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.categories-dropdown {
  position: relative;
  display: inline-block;
}

.all-categories-btn {
  padding: 6px 12px;
  background-color: transparent;
  color: #1a1a1a;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.all-categories-btn:hover {
  background-color: #f3f4f6;
  transform: translateY(-1px);
}

.categories-dropdown-content {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  z-index: 1000;
  min-width: 600px;
  margin-top: 0.5rem;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.category-list li.category-item {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0px;
}

.category-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #f9fafb;
  border: 1px solid #f3f4f6;
  position: relative;
  padding-right: 80px;
}

.category-item:hover {
  background-color: #f3f4f6;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.category-item.active {
  background-color: #f3f4f6;
  font-weight: 500;
}

.clear-button {
  background: black;
  border: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 12px;
  opacity: 0.7;
  color: white;
}

.clear-button:hover {
  opacity: 1;
}

.add-category-btn {
  width: 100%;
  padding: 16px;
  font-size: 1rem;
  color: #6b7280;
  transition: all 0.2s ease;
  background: #f5f5f5;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
}

/* 创建和取消按钮样式 */
.create-category-btn,
.cancel-btn {
  padding: 6px 12px;
  margin: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s ease;
}

.create-category-btn {
  background: #4CAF50;
  color: white;
  position: relative;
}

.create-category-btn:hover {
  background: #45a049;
  transform: translateY(-1px);
}

.create-category-btn:active {
  transform: translateY(1px);
}

.create-category-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
}

.create-category-btn.loading {
  background: #45a049;
  pointer-events: none;
  width: 80px;
  color: transparent;
}

.create-category-btn.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.cancel-btn {
  background: #f44336;
  color: white;
}

.cancel-btn:hover {
  background: #d32f2f;
  transform: translateY(-1px);
}

.cancel-btn:active {
  background: #c62828;
  transform: translateY(1px);
}

/* 按钮容器样式 */
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

/* 分类文本和操作样式 */
.category-text {
  flex-grow: 1;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-actions {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.category-container {
  position: relative;
  display: inline-block;
  z-index: 1500;
}

.category-container.active {
  z-index: 2000;
}

.category-dropdown {
  position: relative;
  display: inline-block;
}

.category-dropdown:hover {
  border-color: rgba(0, 0, 255, 0.2);
}

.category-dropdown:hover .category-actions,
.category-actions:hover,
.category-actions.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) translateX(0);
}

/* 分类操作按钮样式优化 */
.category-action-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.875rem;
  color: #4b5563;
  white-space: nowrap;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin-top: 0;
}

/* 操作按钮悬停效果 */
.category-action-btn:hover {
  background-color: #f8fafc;
  color: #3b82f6;
  transform: translateX(2px);
}

/* Filter 按钮特殊样式 */
.category-action-btn.filter {
  border-bottom: 1px solid #f3f4f6;
  margin-bottom: 4px;
  padding: 8px 16px;
}

.category-action-btn.filter:hover {
  color: #3b82f6;
}

/* Move 按钮特殊样式 */
.category-action-btn.edit:hover {
  color: #10b981;
}

/* 删除分类按钮样式 */
.delete-category-btn {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.delete-category-btn:hover {
  background-color: #fee2e2;
  color: #dc2626;
}

/* 新分类项样式 */
.category-item.new-category {
  background-color: transparent;
  border: 2px dashed #e5e7eb;
  justify-content: center;
  grid-column: 1 / -1;
}

/* 分类编辑相关样式 */
.category-edit-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.category-edit-input {
  flex: 1;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 14px;
}

.category-edit-actions {
  display: flex;
  gap: 4px;
}

.confirm-edit-btn,
.cancel-edit-btn {
  padding: 4px 8px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.confirm-edit-btn {
  color: #22c55e;
}

.confirm-edit-btn:hover {
  background-color: #dcfce7;
}

.cancel-edit-btn {
  color: #666;
}

.cancel-edit-btn:hover {
  color: #333;
  background-color: #f3f4f6;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 类别选择器覆盖层样式 */
.category-selector-overlay {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2000;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    margin-top: 0.5rem;
    min-width: 500px;
    width: auto;
    display: none;
}

/* 当父元素有 active 类时显示 */
.category-container.active .category-selector-overlay {
    display: block;
}

/* 类别选择器网格容器 */
.category-selector-overlay > div:first-child {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-bottom: 12px;
}

/* 类别选择器项样式 */
.category-selector-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #f9fafb;
    border: 1px solid #f3f4f6;
    width: 100%;
    box-sizing: border-box;
    min-width: 200px;
}

.category-selector-item:hover {
    background-color: #f3f4f6;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.category-selector-item.active {
    background-color: #f3f4f6;
    font-weight: 500;
}

/* 类别图标样式 */
.category-selector-icon {
    width: 48px;
    height: 27px;
    margin-right: 8px;
    object-fit: cover;
    border-radius: 6px;
    flex-shrink: 0;
}

/* 类别名称样式 */
.category-selector-name {
    flex: 1;
    font-size: 0.813rem;
    color: #1a1a1a;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 创建新类别选项样式 */
.category-selector-new {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: transparent;
    border: 2px dashed #e5e7eb;
    margin-top: 8px;
    color: #6b7280;
    font-size: 0.875rem;
}

.category-selector-new:hover {
    background-color: #f3f4f6;
    transform: translateY(-1px);
}

/* 编辑输入框样式 */
.category-edit-input {
    flex: 1;
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 0.875rem;
}

/* 编辑操作按钮样式 */
.category-edit-actions {
    display: flex;
    gap: 4px;
}

.confirm-edit-btn,
.cancel-edit-btn {
    padding: 4px 8px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
    font-size: 0.875rem;
}

.confirm-edit-btn {
    color: #22c55e;
}

.confirm-edit-btn:hover {
    background-color: #dcfce7;
}

.cancel-edit-btn {
    color: #666;
}

.cancel-edit-btn:hover {
    color: #333;
    background-color: #f3f4f6;
}

/* 移动设备响应式样式 */
@media (max-width: 768px) {
    .category-selector-overlay {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        width: 100%;
        min-width: 100%;
        border-radius: 12px 12px 0 0;
        margin: 0;
        padding: 1rem;
        max-height: 80vh;
        overflow-y: auto;
    }

    .category-selector-overlay > div:first-child {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }

    .category-selector-item {
        min-width: 0;
    }
}

/* 添加动画效果 */
@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .category-selector-overlay {
        animation: slideUp 0.3s ease-out;
    }
}

/* 分类标签样式优化 */
.category-tag {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.813rem;
    font-weight: 500;
    color: #4b5563;
    background-color: #f3f4f6;
    transition: all 0.2s ease;
    cursor: pointer;
}

.category-tag:hover {
    filter: brightness(0.95);
}

/* 添加菜单打开动画 */
@keyframes menuSlideIn {
    from {
        opacity: 0;
        transform: translateY(-50%) translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(-50%) translateX(0);
    }
}

.category-actions.visible {
    animation: menuSlideIn 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.category-actions button {
    margin-top: 0;
}

/* 当鼠标悬停在分类项上时显示操作按钮 */
.category-item:hover .category-actions {
    opacity: 1;
}

/* 修改编辑和删除按钮的样式 */
.edit-category-btn,
.delete-category-btn {
    padding: 4px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.edit-category-btn:hover {
    background-color: #f3f4f6;
    color: #10b981;
}

.delete-category-btn:hover {
    background-color: #fee2e2;
    color: #dc2626;
}

/* All Categories 下拉菜单中的分类项样式 */
.categories-dropdown-content .category-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    position: relative;
    padding-right: 80px;
}

.categories-dropdown-content .category-actions {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.categories-dropdown-content .category-item:hover .category-actions {
    opacity: 1;
}

/* 卡片中的分类菜单样式 */
.favorite-preview .category-actions {
    position: absolute;
    right: -90px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.2s ease;
    background: white;
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.favorite-preview .category-dropdown:hover .category-actions,
.favorite-preview .category-actions:hover {
    opacity: 1;
    visibility: visible;
}

/* 卡片中的分类操作按钮样式 */
.favorite-preview .category-action-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.875rem;
    color: #4b5563;
    white-space: nowrap;
    transition: all 0.2s ease;
    border-radius: 8px;
    margin-top: 0;
}

.favorite-preview .category-action-btn:hover {
    background-color: #f8fafc;
    color: #3b82f6;
}

/* 确保卡片中的分类标签样式正确 */
.favorite-preview .category-tag {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.813rem;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
}

/* Filter 按钮特殊样式 */
.favorite-preview .category-action-btn.filter {
    border-bottom: 1px solid #f3f4f6;
    margin-bottom: 0;
    padding: 8px 16px;
}

.favorite-preview .category-action-btn.filter:hover {
    color: #3b82f6;
}

/* Move 按钮特殊样式 */
.favorite-preview .category-action-btn.edit:hover {
    color: #10b981;
}

/* 分类项内容布局 */
.category-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-right: 8px;
}

/* 分类数量指示器样式 */
.category-count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    padding: 0 8px;
    background-color: #f3f4f6;
    color: #6b7280;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 12px;
    transition: all 0.2s ease;
}

/* 当分类被选中时的数量指示器样式 */
.category-item.active .category-count {
    background-color: #3b82f6;
    color: white;
}

/* 鼠标悬停效果 */
.category-item:hover .category-count {
    background-color: #e5e7eb;
}

.category-item.active:hover .category-count {
    background-color: #2563eb;
}

/* 确保在移动设备上也能正确显示 */
@media (max-width: 768px) {
    .category-item-content {
        flex: 1;
        margin-right: 4px;
    }

    .category-count {
        min-width: 20px;
        height: 20px;
        font-size: 0.688rem;
        padding: 0 6px;
    }
}

/* 优化分类项布局 */
.category-item {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
}

/* 确保文本不会溢出 */
.category-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
}

/* 分类下拉菜单头部样式 */
.categories-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 16px 0;
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 16px;
    gap: 12px;
}

/* 搜索框容器样式 */
.category-search {
    position: relative;
    width: 240px;
}

.search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    font-size: 14px;
    pointer-events: none;
}

.category-search-input {
    width: 100%;
    padding: 8px 12px 8px 36px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #374151;
    background-color: #f9fafb;
    transition: all 0.2s ease;
}

.category-search-input:focus {
    outline: none;
    border-color: #3b82f6;
    background-color: white;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* 头部操作按钮容器 */
.header-actions {
    display: flex;
    align-items: center;
    gap: 12px;
}

/* 添加新分类按钮样式 */
.add-category-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    background-color: #f3f4f6;
    border: none;
    border-radius: 6px;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s ease;
}

.add-category-button:hover {
    background-color: #e5e7eb;
    color: #3b82f6;
    transform: translateY(-1px);
}

/* 计数切换开关容器 */
.category-count-toggle {
    display: flex;
    align-items: center;
}

.toggle-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    background-color: #f3f4f6;
    transition: all 0.2s ease;
}

.toggle-label:hover {
    background-color: #e5e7eb;
}

/* 响应式调整 */
@media (max-width: 640px) {
    .categories-header {
        flex-wrap: wrap;
        gap: 8px;
    }

    .category-search {
        width: 100%;
        order: 1;
    }

    .header-actions {
        width: auto;
        order: 2;
        justify-content: flex-end;
    }
}

/* 新分类输入表单样式 */
.new-category-form {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 300px;
    z-index: 1100;
    animation: slideDown 0.2s ease-out;
}

.new-category-input {
    width: 90%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.875rem;
    margin-bottom: 12px;
    transition: all 0.2s ease;
}

.new-category-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.new-category-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.create-btn,
.cancel-btn {
    padding: 6px 16px;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.create-btn {
    background-color: #3b82f6;
    color: white;
    border: none;
}

.create-btn:hover {
    background-color: #2563eb;
}

.cancel-btn {
    background-color: #f3f4f6;
    color: #4b5563;
    border: 1px solid #e5e7eb;
}

.cancel-btn:hover {
    background-color: #e5e7eb;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
