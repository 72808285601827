/* css/AuthMenu.css */
:root {
    --menu-bg: #ffffff;
    --menu-text: #1A1D1F;
    --menu-hover: #F4F5F6;
    --menu-border: #E6E8EC;
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    --menu-transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.auth-menu {
    position: relative;
    z-index: 50;
}

.auth-menu-trigger {
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--menu-text);
    background-color: transparent;
    border: 1px solid var(--menu-border);
    border-radius: 8px;
    cursor: pointer;
    transition: all var(--menu-transition);
}

.auth-menu-trigger:hover,
.auth-menu-trigger:focus-visible {
    background-color: var(--menu-hover);
}

.auth-menu-dropdown {
    position: absolute;
    right: 0;
    top: calc(100% + 0.5rem);
    width: 180px;
    background-color: var(--menu-bg);
    border: 1px solid var(--menu-border);
    border-radius: 8px;
    box-shadow: var(--shadow-md);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-8px);
    transition: all var(--menu-transition);
}

.auth-menu-dropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.auth-menu-content {
    padding: 0.5rem;
}

.auth-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    color: var(--menu-text);
    background: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all var(--menu-transition);
    text-align: left;
}

.auth-menu-item:hover {
    background-color: var(--menu-hover);
}

@media (max-width: 768px) {
    .auth-menu-trigger {
        padding: 0.625rem 1rem;
    }
}

@media (max-width: 480px) {
    .auth-menu-dropdown {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        width: 100%;
        border-radius: 12px 12px 0 0;
        transform: translateY(100%);
    }

    .auth-menu-dropdown.active {
        transform: translateY(0);
    }

    .auth-menu-content {
        padding: 1rem;
    }

    .auth-menu-item {
        padding: 1rem;
        justify-content: center;
    }
}