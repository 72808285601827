/* css/Header.css */
.app-header {
    padding: 0;
    background: transparent;
    width: 100%;
}

.header-content {
    max-width: 100%;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #111827;
    gap: 1rem;
    transition: opacity 0.2s ease;
}

.logo-link:hover {
    opacity: 0.9;
}

.app-logo {
    width: 2.25rem;
    height: 2.25rem;
}

.app-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: #111827;
}

.header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.back-to-app {
    padding: 0.5rem 1rem;
    color: #4f46e5;
    text-decoration: none;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.2s ease;
    background-color: white;
}

.back-to-app:hover {
    background-color: #f9fafb;
    border-color: #d1d5db;
    color: #4338ca;
}

@media (max-width: 768px) {
    .header-content {
        padding: 1.25rem 1.5rem;
    }
}

@media (max-width: 480px) {
    .header-content {
        padding: 1rem;
    }

    .app-title {
        font-size: 1.25rem;
    }

    .back-to-app {
        padding: 0.375rem 0.75rem;
        font-size: 0.75rem;
    }

    .app-logo {
        width: 2rem;
        height: 2rem;
    }
}